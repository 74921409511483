<template>
  <div>
    <b-card no-body>
      <!-- User Interface controls -->
      <b-card-header class="bg-light">
        <b-row>
          <b-col md="8" xl="5">
            <b-input-group>
              <b-form-input v-model="filter" class="form-control" placeholder="Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>

          <b-col md="4" xl="2" class="offset-xl-5">
            <b-form-group label-cols-sm="4" label-cols-xl="6" label="Per page" class="mb-0">
              <b-form-select v-model="perPage" :options="pageOptions" class="form-control"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-header>

      <!-- Main table element -->
      <b-table
        class="card-table"
        show-empty
        stacked="md"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template slot="name" slot-scope="row">{{ row.value.first }} {{ row.value.last }}</template>

        <template slot="isActive" slot-scope="row">{{ row.value ? 'Yes :)' : 'No :(' }}</template>

        <template slot="actions" slot-scope="row">
          <b-button
            size="sm"
            @click="info(row.item, row.index, $event.target)"
            class="mr-1"
          >Info modal</b-button>
          <b-button
            size="sm"
            @click="row.toggleDetails"
          >{{ row.detailsShowing ? 'Hide' : 'Show' }} Details</b-button>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
            </ul>
          </b-card>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-card-footer>
    </b-card>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TopProductsDrillDown', // TableBootstrapComplete
  data() {
    return {
      items: [],
      fields: [
        { key: 'qty', label: 'Quantity', sortable: true, sortDirection: 'desc', class: 'text-center', tdClass: 'td-md-shrink' },
        { key: 'prodName', label: 'Product Name', sortable: true },
        { key: 'prodId', label: 'Product Id', sortable: true },
        { key: 'catName', label: 'Category', sortable: true },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateChart(details) {
      this.items = details
      this.totalRows = details.length
    },

    triggerUpdateChart() {
      this.updateChart(this.getProductsOrderedPerCategory(this.orderedProductsDetails))
    },

    getProductsOrderedPerCategory(orderDetails) {
      const groupProducts = []
      const orderedProducts = orderDetails || []

      // Grouping Products and adding the ordered quantity to get total
      orderedProducts.reduce((res, value) => {
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          const prodCat = prodItem.c_productCategory || 'Unknown Category'
          if (!res[prodItem.product_id]) {
            res[prodItem.product_id] = { prodId: prodItem.product_id, prodName: prodItem.product_name, qty: 0, catName: prodCat }
            groupProducts.push(res[prodItem.product_id])
          }
          res[prodItem.product_id].qty += prodItem.quantity
        })
        return res
      }, {})

      // Sorting products based on higher quantity
      groupProducts.sort((a, b) => b.qty - a.qty)

      return groupProducts
    },
  },

  watch: {
    orderedProductsDetails() {
      this.triggerUpdateChart()
    }
  },
}
</script>
