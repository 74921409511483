<template>
  <div class="row mb-4">
    <div class="offset-8 col-2 text-right my-auto">
      <strong>Reports By: </strong>
    </div>
    <div class="col-2 pl-0">
      <b-form-select size="sm" id="dateType" name="dateType" v-model="dateTypeSelected" @change="dateTypeChange">
        <b-form-select-option value="creation_date">Order Date</b-form-select-option>
        <b-form-select-option value="c_shippedDate">Mail Date</b-form-select-option>
      </b-form-select>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      dateTypeSelected: null,
    }
  },
  computed: {
    ...mapState('admin', [
      'globalDateTypeSelected',
    ]),
  },

  methods: {
    ...mapActions('admin', [
      'updateGlobalDateTypeSelected',
    ]),

    dateTypeChange: function() {
      this.updateGlobalDateTypeSelected(this.dateTypeSelected)
        .then(this.$parent.triggerUpdateChart())
    },

  },

  mounted() {
    this.dateTypeSelected = this.globalDateTypeSelected;
  },
}
</script>
