<template>
  <div>
    <util-date-type-picker />
    <util-date-range-picker />

    <div class="cui__utils__heading">
      <strong>Top 10 Products</strong>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="position-relative">
          <vue-chartist
            class="height-600 ct-hidden-points"
            type="Bar"
            :data="data"
            :options="options"
          />
        </div>
      </div>
    </div>

    <div class="cui__utils__heading">
        <strong>Top Products Drill down</strong>
    </div>
    <table-top-products-drill-down />

  </div>
</template>

<script>
import DateTypePicker from './DateTypePicker'
import DateRangePicker from './DateRangePickerHelper'
import TopProductsDrillDown from './TopProductsDrillDown'
import data from './data.json'
import VueChartist from 'v-chartist'
import ChartistTooltip from 'chartist-plugin-tooltips-updated'
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  name: 'TopProducts',
  components: {
    'vue-chartist': VueChartist,
    'util-date-type-picker': DateTypePicker,
    'util-date-range-picker': DateRangePicker,
    'table-top-products-drill-down': TopProductsDrillDown,
  },
  data: function () {
    const options = {
      fullWidth: true,
      showLabel: true,
      horizontalBars: true,
      axisX: {
        showGrid: true,
        showLabel: true,
        offset: 15,
        onlyInteger: true,
      },
      axisY: {
        showGrid: false,
        showLabel: true,
        offset: 100,
      },
      chartPadding: 20,
      low: 0,
      plugins: [ChartistTooltip({ anchorToPoint: true, appendToBody: true, seriesName: true })],
    }

    return {
      data,
      options,
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
      'globalDateRange',
      'globalClientSelected',
      'globalDateTypeSelected',
    ]),
  },
  methods: {
    ...mapActions('admin', [
      'fetchOrderedProductDetails',
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading',
    ]),

    triggerUpdateChart() {
      this.startLoading()
      this.fetchOrderedProductDetails({
        saveToStateObj: 'setOrderedProductsDetails',
        selectFilter: '(count,total,hits.(data.(product_items.(product_id,product_name,quantity,c_productCategory))))',
        dateType: this.globalDateTypeSelected,
        fromDate: this.globalDateRange.fromDate,
        toDate: this.globalDateRange.toDate,
      })
        .then(() => this.updateChart(this.getTopProductsBasedOnQuantity(this.orderedProductsDetails)))
        // Update Top Products Drilldown table - Using Watch so commenting
        // .then(() => this.$children[2].triggerUpdateChart())
        .then(() => this.stopLoading())
    },

    updateChart(topProducts) {
      const product = []
      const quantity = []
      topProducts.forEach(topProduct => {
        product.push(topProduct.prodName)
        quantity.push(topProduct.qty)
      })

      this.data = {
        series: [quantity],
        labels: product,
      }
    },

    // Filtering Top 10 Products based on highest ordered quantity
    getTopProductsBasedOnQuantity(orderDetails) {
      const groupProducts = []
      // const orderedProducts = Object.values(orderDetails);
      const orderedProducts = orderDetails || []

      // Grouping Products and adding the ordered quantity to get total
      orderedProducts.reduce((res, value) => {
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          if (!res[prodItem.product_id]) {
            res[prodItem.product_id] = { prodId: prodItem.product_id, prodName: prodItem.product_name, qty: 0 }
            groupProducts.push(res[prodItem.product_id])
          }
          res[prodItem.product_id].qty += prodItem.quantity
        })
        return res
      }, {})

      // Sorting products based on higher quantity
      groupProducts.sort((a, b) => b.qty - a.qty)

      // Saving only Top 10 products and discarding rest of the array
      groupProducts.length = 10

      return groupProducts
    },
  },

  mounted() {
    this.triggerUpdateChart()
  },

  watch: {
    globalClientSelected() {
      this.triggerUpdateChart()
    }
  },
}
</script>
